var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "statu-mod", style: _vm.getStyle }, [
    _vm._v(" " + _vm._s(_vm.stateName) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
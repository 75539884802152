<!--
 * @Author: pengyu
 * @Date: 2020-07-23 09:42:26
 * @LastEditors: pengyu
 * @LastEditTime: 2020-07-23 16:05:53
 * @Description: 进度字段 状态组件
-->
<template>
 <div class="statu-mod" :style="getStyle">
   {{stateName}}
 </div>
</template>

<script>
export default {
  name: "StatuMod",
  props: {
    state: {
      type: Number,
      default: 1
    }
  },
  computed: {
    stateName() {
      const sobj = {
        1: "未开始", 2: "已完成", 3: "已延期", 4: "逾期完成", 5: "预警中", 6: "进行中"
      };
      return sobj[this.state];
    },
    getStyle() {
      const st = this.state;
      switch (st) {
        case 1:
          return {
            color: "rgb(70,148,223)",
            background: "rgba(70,148,223,0.1)"
          };
        case 2:
          return {
            color: "rgb(38,195,147)",
            background: "rgba(38,195,147,0.1)"
          };
        case 3:
          return {
            color: "rgb(247,107,107)",
            background: "rgba(247,107,107,0.1)"
          };
        case 4:
        case 5:
        case 6:
          return {
            color: "rgb(255,173,44)",
            background: "rgba(247,107,107,0.1)"
          };
        default:
          return {};
      }
    }
  },
  data() {
    return {
    };
  }
};
</script>

<style lang='scss' scoped>
.statu-mod{
  display: inline-block;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 12px;
}
</style>

<!--
 * @Author: pengyu
 * @Date: 2020-07-23 09:30:31
 * @LastEditors: linshanfeng
 * @LastEditTime: 2023-11-02 16:07:55
 * @Description: 进度控件
-->
<template>
  <div class="progress">
  <el-collapse @change="changeContent" v-model="showContent">
    <el-collapse-item name="1" >
      <template slot="title">
      <div class="header">
        <!-- 占行控件样式 -->
        <en-icon  name="iconjindu-biaoti" size="14px" style="margin-right:5px;color:#FFAD2C" ></en-icon>
        <div class="header-title">{{ column.name }}</div>
        <en-icon  name="iconzhankai" color="#A9B5C6" class="drop-down" :style="{transform: showContent.length ===  0 ? 'rotate(270deg)':'rotate(0deg)'}" ></en-icon>
        </div>
      </template>
      <div class="progress-container" v-if="column.issub!==2">
        <!-- 滑块 -->
        <el-slider
          :min="0"
          :max="100"
          height="14px"
          v-model="column.showValue"
          :class="`state${state}`"
          :disabled="!column.editable"
          :format-tooltip="formatToolTip"
          @change="handleSliderChange"
        ></el-slider>
        <!-- 显示 -->
        <div class="cur-value">
          <span :class="`plan-value-${state}`">{{`${column.showValue}%`}}</span>
        </div>
        <!-- 计划进度 -->
        <div class="plan-value">
          <span>计划进度：</span>
          <span :class="`plan-value-${state}`" v-show="planValue!==''">{{`${planValue}%`}}</span>
        </div>
        <!-- 状态 -->
        <div class="state-value">
          <stateMod :state="state"></stateMod>
        </div>
      </div>
      <div class="progress-container-issub" v-else>
        <span>实际进度：</span>
        <el-input type="number" placeholder="请输入" v-model="column.showValue" @change="handleSliderChange"></el-input>
      </div>
  </el-collapse-item>
  </el-collapse>
</div>
</template>

<script>
import stateMod from "./components/stateMod";
import { AssignmentOptions, BusinessFieldType } from "../../../data/BusinessCommonHeader.js";

export default {
  name: "BMProgressColumn",
  components: { stateMod },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return { showValue: "" };
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    "column.showValue": {
      immediate: true,
      handler(nVal) {
        // eslint-disable-next-line no-bitwise
        // if (this.column.lastAssignment === AssignmentOptions.change) {
          // 被动改变及详情初始值
          this.handlePlanProgress(nVal);
        // }
      }
    }
  },
  data() {
    return {
      planValue: "", // 计划进度
      state: 0,
      showContent: ["1"]
    };
  },
  methods: {
    changeContent(e) {
      this.showContent = e;
    },
    /**
     * @description: 滑块变化
     * @param value
     */
    handleSliderChange(value) {
      value = Number(value);
      let relatedPlanFlag;
      const { mainAreaFields } = this.column;
      this.handlePlanProgress(value);
      this.column.updateValueWithOptions(value, AssignmentOptions.manual);
      // 被标记完成关联进度
      const mark = mainAreaFields.filter((itm) => itm.fieldType === BusinessFieldType.checkBox);
      if (mark[0]) {
        relatedPlanFlag = mark[0].relatedPlanFlag;
      }
      if (relatedPlanFlag === 0) {
        mark[0].updateValueWithOptions(value === 100 ? 0 : 1, AssignmentOptions.change);
        const { targetField } = mark[0];
        if (targetField) {
          const tf = this.column.findMainAreaFieldByUuid(targetField);
          tf.isComplete = value === 100;
        }
      }
      // 还需要处理子项中这些逻辑
    },
    handlePlanProgress(value) {
      const {
        relatedDateType, relatedDate, relatedEndDate, columnValue
      } = this.column;
      // 找出进度字段
      let setData = {};

      if (value === columnValue.value) {
        setData = columnValue;
      } else if (relatedDateType === 1) { // 由时间区间字段控制
        const fd = this.column.findMainAreaFieldByUuid(relatedDate);
        const time = fd?.showValue;
        if (time) {
          setData = this.calculate(time);
        } else {
          setData = { value, time: new Date().getTime() };
        }
      } else if (relatedDateType === 2) { // 时间字段控制
        const fds = this.column.findMainAreaFieldByUuid(relatedDate);
        const fde = this.column.findMainAreaFieldByUuid(relatedEndDate);
        if (fds?.showValue && fde?.showValue) {
          setData = this.calculate([fds?.showValue, fde?.showValue]);
        } else {
          setData = { value, time: new Date().getTime() };
        }
      } else {
        setData = { value, time: new Date().getTime() };
      }

      this.column.setData = setData;
      this.state = setData.state || 0;
      this.planValue = setData.plan || "";
    },
    /**
     * @description: 计算进度  state  1未开始  2已完成 3已延期 4逾期完成 5预警中 6进行中
     * @param time:Array
     */
    calculate(time) {
      const value = this.column.showValue;
      const [st, et] = time;
      const sts = new Date(st).getTime();
      const ets = new Date(et).getTime();
      const now = new Date().getTime();
      let plan; let state;
      if (sts === ets) { // 开始时间等于结束时间
        if (now > ets) { // 当前时间点大于结束时间
          plan = 100;
          state = value === 100 ? 4 : 3;
        } else if (now === ets) { // 当前时间点等于结束时间
          plan = 100;
          state = value === 100 ? 2 : 3;
        } else { // 当前时间点小于结束时间
          plan = 0;
          state = 1;
        }
      } else if (sts > now) { // 开始时间晚于现在
        state = 1; plan = 0;
      } else if (ets < now) { // 结束时间早于现在
        plan = 100;
        state = value === 100 ? 4 : 3;
      } else { // 现在处于 开始和结束之间
        // eslint-disable-next-line no-mixed-operators
        plan = ((now - sts) / (ets - sts) * 100).toFixed(1);
        state = value === 100 ? 2 : value >= plan ? 6 : 5;
      }

      return {
        value, time: new Date().getTime(), plan, state
      };
    },
    /**
     * @description: tooltip格式化
     */
    formatToolTip(value) {
      return `${value}%`;
    }
  }
};
</script>

<style lang='scss' scoped>
/deep/ .el-collapse {
    // border-top: none;
    border-bottom: none;
  }
  /deep/.el-collapse-item__header{
    border-bottom:none
  }
/deep/.el-collapse-item__header .el-icon-arrow-right{
        display: none !important;
    }
.progress{
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 0;
    .header-title {
      color: #1A1C1E;
      font-size:14px;
      font-weight:bold;
    }
    .drop-down{
      margin-left: 5px;
      width:12px;
      height:12px;
    }
  }
}
.progress-container{
  width: 90%;
  display: flex;
  align-items: center;
  background: #F6F7FB;
  border: 1px solid $field-border-color;
  border-radius: 20px;
  height: 40px;
  padding: 0 30px;
  box-sizing: border-box;
  & /deep/ .el-slider{
    flex:5;
    margin-right: 10px;
    .el-slider__button{
      border:1px solid #c0c4cc;
    }
    &.state1 .el-slider__bar,
    &.state5 .el-slider__bar,
    &.state6 .el-slider__bar{
      background: #3e90fe;
    }
    &.state2 .el-slider__bar,
    &.state4 .el-slider__bar{
      background: #26c393;
    }
    &.state3 .el-slider__bar{
      background: #f76b6b;
    }
  }
  .cur-value,.state-value,.plan-value{
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: none;
  }
  .cur-value{
    flex: 0.5;
  }
  .plan-value,.cur-value{
    .plan-value-1,
    .plan-value-5,
    .plan-value-6{
      color: #3e90fe;
    }
    .plan-value-2,
    .plan-value-4{
      color: #26c393;
    }
    .plan-value-3{
      color: #f76b6b  ;
    }
  }
}
.progress-container-issub{
  width: 100%;
  display: flex;
  align-items: center;
  span{
    width: 80px;
  }
  & /deep/ .el-input{
    border: none;
  }
}
</style>
